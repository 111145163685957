html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  overflow: auto;
}
